import {
    Button,
    Dropdown,
    Input,
    Label,
    Option,
    Text,
    Switch,
    makeStyles, shorthands, Divider, Combobox, SkeletonItem, Spinner, Tab,
    TabList, Popover,
    PopoverSurface,
    PopoverTrigger,
} from "@fluentui/react-components";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import Layout from "./components/Layout";
import ProductList from "./components/ProductList";
import { Base_Url, auth, trackException } from "./services/firebase";
import OrderHistoryDataService from './services/orderHistoryDataService';
import UserDataService from "./services/userDataService";
import {
    AddGreenSVG,
    DownArowSVG,
    ErrorWithWarningSVG,
    InfoSVG,
    InstructionsSVG, PaymentIntentIDSVG, PaymentIntentStatusSVG, PaymentSummrySVG, PlusSVG, ReaderStatusSVG,
    SearchSVG,
    SelectProductFilterAll, SelectProductFilterDrinks, SelectProductFilterFood,
    SelectProductSVG, SendTerminalSVG, UPArrowSVG, plusSVG
} from "./static/svgs";
import "./TerminalReader.css"
import { useDispatch, useSelector } from "react-redux";
import { Timestamp } from "firebase/firestore";
import userDataService from "./services/userDataService";
import { getMemberList, setMemberList } from "./slices/memberSlice";
import productCartDataService from "./services/productCartDataService";
import { BookingCategory } from "./Helper";

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },
    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },
    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    },

});
function POSOrder() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isSidebarOpen = useSelector(state => state.layout?.isSidebarOpen)
    const placeholderImageUrl = 'https://placehold.co/800?text=No+Image&font=roboto';
    const styles = useStyles();
    const [user, loading, error] = useAuthState(auth);
    const [open, setOpen] = React.useState(false);
    const [checked, setChecked] = React.useState(true);
    const [isGratuityOn, setIsGratuityOn] = useState(false);
    const [isSalesTaxOn, setIsSalesTaxOn] = useState(false);

    const userList = useSelector(getMemberList);
    const [filterUserList, setFilterUserList] = useState([])

    const [isShowInstruction, setIsShowInstruction] = useState(true)

    const [categoryList, setCategoryList] = useState([]);
    const [readerList, setReaderList] = useState([]);
    const [filterState, setFilterState] = useState({ selectedFilter: "All" });
    const [selectedReaderId, setSelectedReaderId] = useState(null);

    const [productListArr, setProductList] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [adjustedProducts, setAdjustedProducts] = useState([]);

    const [paymentIntentObj, setPaymentIntentObj] = useState(null);
    const [readerObj, setReaderObj] = useState(null);
    const [msgText, setMsgText] = useState('');

    const [additionalTip, setAdditionalTip] = useState(0);
    const [salesTaxRate, setSalesTaxRate] = useState('7.45');
    const [selectedProductIds, setSelectedProductIds] = useState([]);
    const [memberNumber, setMemberNumber] = useState('');
    const [guestName, setGuestName] = useState('');
    const [memberDetails, setMemberDetails] = useState(null);
    const [accountHolderData, setAccountHolderData] = useState(null);
    const [memberHasParent, setMemberHasParent] = useState(false);

    const [userListByLastName, setUserListByLastName] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
    const [isOrderSave, setIsOrderSave] = useState(false);
    const [orderListArr, setOrderListArr] = useState([]);
    const [tabListArr, setTabListArr] = useState([]);
    const [selectedTabValue, setSelectedTabValue] = React.useState("");

    const [popupMessage, setPopupMessage] = React.useState("");
    const [nextProductId, setNextProductId] = useState(null);

    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `${user.accessToken}`,
            'Accept': "application/json"
        }
    };

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        getReaderList()
        getProductsListAPI()
        getAllUsers()
        getCartOrderList()
    }, [user, loading]);

    useEffect(() => {
        if (selectedProducts) {
            let adjustedArr = adjustProductQuantity(selectedProducts);
            setAdjustedProducts(adjustedArr)
        }
    }, [selectedProducts])

    useEffect(() => {
        setFilterUserList(userList)
    }, [userList])

    useEffect(() => {
    }, [tabListArr])

    useEffect(() => {
        if (selectedTabValue) { updatedSeletedProductOnOrderChange(selectedTabValue) }
    }, [selectedTabValue])

    const handleOpenChange = (e, data) => {
        setOpen(data.open || false);
    }

    const onTabSelect = (event, data) => {
        setSelectedTabValue(data.value);
    };

    async function getAllUsers() {
        const usersQuery = await userDataService.getAllUser();
        let allUserList = usersQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        dispatch(setMemberList(allUserList));
    }

    async function updatedSeletedProductOnOrderChange(selectedTabValue) {
        if (selectedTabValue == "NEW") {
            resetAndCollectNew()
        } else {
            const filteredArr = orderListArr.filter(item => { return (item.id == selectedTabValue) });
            if (filteredArr && filteredArr.length > 0) {
                let order = filteredArr[0]
                setSelectedProducts(order.selectedProducts);
                const idArray = order.selectedProducts.map(product => product.id);
                setSelectedProductIds(idArray);

                const memberArr = userList.filter(item => { return (item.id == order.memberId) });
                if (memberArr && memberArr.length > 0) {
                    setMemberDetails(memberArr[0])
                    setMemberNumber(memberArr[0].memberNumber)
                    setChecked(true)
                    checkUserHasParent(memberArr[0])
                } else {
                    setChecked(false) //isGuest
                }
            }
        }
    }

    const getUserData = () => {
        if (memberHasParent && accountHolderData) {
            return accountHolderData;
        } else {
            return memberDetails;
        }
    }
    const getMemberBalance = () => {
        let userInfo = getUserData()
        return userInfo && userInfo.totalAmount && parseInt(userInfo.totalAmount) > 0 ? parseFloat(userInfo.totalAmount).toFixed(2) : 0.00
    }

    async function checkUserHasParent(userdata) {
        if (userdata && userdata.accountHolderId && userdata.accountHolderId != "" && userdata.accountHolderId != userdata.id) {
            try {
                const userDoc = await UserDataService.getUser(userdata.accountHolderId);
                let parentuser = userDoc.data();
                if (parentuser) {
                    setMemberHasParent(true)
                    setAccountHolderData(parentuser)
                }
            } catch (error) {
                setMemberHasParent(false)
                setAccountHolderData(null)
            }
        } else {
            setMemberHasParent(false)
            setAccountHolderData(null)
        }
    }

    //Firebase functions
    const toggleProductSelection = (productId) => {
        setSelectedProductIds([...selectedProductIds, productId]);
    };
    async function getUserDetails() {
        try {
            const userInfo = await UserDataService.getUserByMemberId(memberNumber);
            if (userInfo.docs.length > 0) {
                let memberData = userInfo.docs[0].data();
                setMemberDetails(memberData)
                checkUserHasParent(memberData)
            } else {
                setMemberDetails(null)
                checkUserHasParent(null)
                alert("No member found.")
            }

        } catch (error) {
            setMemberDetails(null)
            checkUserHasParent(null)
            console.log(error)
            trackException(JSON.stringify(error))
            alert(error)
        }
    }

    async function addOrderHistoryFIR(intentRes, email) {
        //console.log("intentRes->", intentRes)
        const nowTimestamp = Timestamp.fromDate(new Date());
        let data = {
            "id": "",
            "email": checked ? email : 'guest@gmail.com',
            "guestName": checked ? '' : guestName,
            "intentId": intentRes.id,
            "amount": intentRes.amount,
            "metadata": intentRes.metadata,
            "products": adjustedProducts,
            "currency": intentRes.currency,
            "customer": intentRes.customer ? intentRes.customer : "",
            "description": intentRes.description ? intentRes.description : "",
            "receipt_email": intentRes.receipt_email,
            "status": intentRes.status,
            "intentCreatedAt": intentRes.created,
            "createdDate": new Date().toISOString(),
            "createdAt": nowTimestamp,
            "createdBy": user.uid,
            "receipt_url": intentRes.receipt_url
        }
        const addeddata = await OrderHistoryDataService.addOrderHistory(data);
        let id = addeddata.id;
        data.id = id;
        await OrderHistoryDataService.updateOrderHistory(id, data);




        if (selectedTabValue != "" && selectedTabValue != "NEW") {
            deleteCartOrderFromFIR(selectedTabValue)
        }
    }

    async function getCartOrderList() {
        try {
            const cartQuery = await productCartDataService.getAllPOSCart();
            let cartOrderArr = cartQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            if (cartOrderArr && cartOrderArr.length > 0) {
                setOrderListArr(cartOrderArr)
                let tabArr = [];
                for (let index = 0; index < cartOrderArr.length; index++) {
                    const element = cartOrderArr[index];
                    const lastFive = element.id.substring(element.id.length - 5);
                    if (element.isGuest) {
                        let obj = { id: element.id, label: `${element.guestName} (${lastFive})` }
                        tabArr.push(obj)
                    }
                    else if (element.member && element.member.memberName) {
                        let obj = { id: element.id, label: `${element.member.memberName} (${lastFive})` }
                        tabArr.push(obj)
                    }
                }
                setIsOrderSave(true)
                setTabListArr(tabArr)
                setSelectedTabValue(tabArr[0].id)
            } else {
                setIsOrderSave(true)
                setTabListArr([])
                setSelectedTabValue("")
            }
        } catch (error) {
            trackException(JSON.stringify(error))
        }
    }

    async function deleteCartOrderFromFIR(id) {
        try {
            const deleteObj = await productCartDataService.deletePOSCart(id);
            getCartOrderList()
        } catch (error) {
            trackException(JSON.stringify(error))
        }
    }
    //API
    const getReaderList = async () => {
        let config = {
            method: 'get',
            url: `${Base_Url}/getStripeTerminalReaders`,
            headers: {
                'Content-Type': 'application/json',
                'Acess-Control-Allow-Origin': '*',
                'Authorization': `${user.accessToken}`,
                'Accept': "application/json"
            },
        };
        axios.request(config)
            .then((response) => {
                if (response && response.data && response.data.data) {
                    setReaderList(response.data.data.data)
                }
            })
            .catch((error) => {
                console.log(error);
                trackException(JSON.stringify(error))
            });
    }

    const getSelectedProductsMetadata = () => {
        return adjustedProducts.map(({ id, productName, price, quantity, images }) => ({
            id,
            price,
            quantity,
        }));
    };

    const processPaymentIntentsAPI = async (readerId) => {
        if (selectedProducts && selectedProducts.length > 0) {
            setIsLoading(true)
            setIsPaymentProcessing(true)
            let amount = calculateTotalPriceWithGratuity()
            let userInfo = getUserData()
            let otherData = {
                additionalTip: additionalTip,
                gratuity: calculateGratuity(),
                saletax: calculateSalesTax(),
                memberEmail: checked ? memberDetails.email : 'guest@gmail.com',
                parentEmail: checked && userInfo ? userInfo?.email : '',
                isFromCardReader: true
            }
            let metadata = { productInfo: JSON.stringify(getSelectedProductsMetadata()), otherInfo: JSON.stringify(otherData) }
            let data;
            if (checked) {
                data = JSON.stringify({
                    "readerId": readerId,
                    "amount": amount,
                    "metadata": metadata,
                    "customerId": userInfo.stripeCustomerId ? userInfo.stripeCustomerId : "",
                    "receiptEmail": userInfo.email,
                    "description": "POS: Charging you for your recent meal."
                });
            } else {
                data = JSON.stringify({
                    "readerId": readerId,
                    "amount": amount,
                    "metadata": metadata,
                    "receiptEmail": 'guest@gmail.com',
                    "guestName": guestName,
                    "description": "POS: Charging you for your recent meal."
                });
            }
            let config = {
                method: 'post',
                url: `${Base_Url}/terminalReadersProcessPaymentIntents`,
                headers: {
                    'Content-Type': 'application/json',
                    'Acess-Control-Allow-Origin': '*',
                    'Authorization': `${user.accessToken}`,
                    'Accept': "application/json"
                },
                data: data
            };
            axios.request(config).then((response) => {
                setIsLoading(false)
                setIsPaymentProcessing(false)
                if (response && response.data) {
                    setPaymentIntentObj(response.data.paymentIntent)
                    setReaderObj(response.data.reader)
                    setMsgText(`Processing payment for ${amount} on reader ${JSON.stringify(response.data.reader.id)}`)
                }
            }).catch((error) => {
                setIsLoading(false)
                setIsPaymentProcessing(false)
                console.log(error);
                setPaymentIntentObj(null)
                setReaderObj(null)
                trackException(JSON.stringify(error))
            });
        }
    }

    const simulatePresentingPaymentAPI = async (readerId) => {
        let data = JSON.stringify({
            "readerId": readerId
        });
        let config = {
            method: 'post',
            url: `${Base_Url}/simulatePresentingPayment`,
            headers: {
                'Content-Type': 'application/json',
                'Acess-Control-Allow-Origin': '*',
                'Authorization': `${user.accessToken}`,
                'Accept': "application/json"
            },
            data: data
        };
        axios.request(config).then((response) => {
            if (response && response.data) {
                let msg = msgText.toString() + `\n\nSimulating a customer tapping their card on simulated reader ${readerObj.id} for payment ${paymentIntentObj.id}`
                setMsgText(msg)
                setReaderObj(response.data.reader)
            }
        }).catch((error) => {
            console.log(error);
            setPaymentIntentObj(null)
            setReaderObj(null)
            trackException(JSON.stringify(error))
        });
    }

    const readersCancelActionAPI = async (readerId) => {
        let data = JSON.stringify({
            "readerId": readerId
        });
        let config = {
            method: 'post',
            url: `${Base_Url}/readersCancelAction`,
            headers: {
                'Content-Type': 'application/json',
                'Acess-Control-Allow-Origin': '*',
                'Authorization': `${user.accessToken}`,
                'Accept': "application/json"
            },
            data: data
        };
        axios.request(config).then((response) => {
            if (response && response.data) {
                setPaymentIntentObj(null)
                setReaderObj(null)
                setMsgText('')
            }
        }).catch((error) => {
            console.log(error)
            setPaymentIntentObj(null)
            setReaderObj(null)
            setMsgText('')
            trackException(JSON.stringify(error))
        });
    }


    useEffect(() => {
        if (nextProductId) {
            loadMoreProductsListAPI(nextProductId);
        }
    }, [nextProductId]);
    async function getProductsListAPI() {
        try {
            setIsLoading(true);
            const response = await axios.get(`${Base_Url}/getPOSStripeProductsPrice`, axiosConfig);
            setIsLoading(false);
            if (response?.data?.data) {
                manageProductListData(response.data.data, true);
                if (response.data.hasMore) {
                    setNextProductId(response.data.data[response.data.data.length - 1].id);
                }
            }
        } catch (error) {
            setIsLoading(false);
            console.error(error);
            trackException(JSON.stringify(error));
        }
    }

    async function loadMoreProductsListAPI(id) {
        try {
            const response = await axios.get(`${Base_Url}/getPOSStripeProductsPrice?starting_after=${id}`, axiosConfig);
            if (response?.data?.data) {
                manageProductListData(response.data.data, false);
                if (response.data.hasMore) {
                    setNextProductId(response.data.data[response.data.data.length - 1].id);
                } else {
                    setNextProductId(null); // No more products to load
                }
            }
        } catch (error) {
            console.error(error);
            trackException(JSON.stringify(error));
        }
    }

    function manageProductListData(responsedata, isReset) {
        const filteredProducts = responsedata.filter(product => product.metadata && product.metadata.Category !== "Subscription");
        const categoriesArr = isReset ? ["All"] : [...categoryList];
        const allProductArr = isReset ? [] : [...productListArr];

        filteredProducts.forEach(product => {
            if (product.metadata?.C) {
                categoriesArr.push(product.metadata.C);
                allProductArr.push(product);
            }
        });
        const uniqueCategories = Array.from(new Set(categoriesArr));
        setCategoryList(uniqueCategories);
        setProductList(allProductArr);
    }

    const checkIntentStatusAPI = async (paymentIntentId) => {
        setIsLoading(true)
        let data = JSON.stringify({
            "paymentIntentId": paymentIntentId
        });
        let config = {
            method: 'post',
            url: `${Base_Url}/getPaymentIntentById`,
            headers: {
                'Content-Type': 'application/json',
                'Acess-Control-Allow-Origin': '*',
                'Authorization': `${user.accessToken}`,
                'Accept': "application/json"
            },
            data: data
        };
        axios.request(config).then((response) => {
            setIsLoading(false)
            if (response && response.data) {
                setPaymentIntentObj(response.data.data)
                if (response.data.data?.status === 'succeeded') {
                    //TODO: what to do
                    addOrderHistoryFIR(response.data.data, memberDetails?.email)
                }
            }
        }).catch((error) => {
            setIsLoading(false)
            console.log(error);
            trackException(JSON.stringify(error))
        });
    }

    const chargeOnFileCreditPointAPI = async () => {
        if (selectedProducts && selectedProducts.length > 0) {
            setIsLoading(true)
            setIsPaymentProcessing(true)
            let amount = calculateTotalPriceWithGratuity()
            let chargefromMinimum = calculateTotalPriceForMinimum()

            let userInfo = getUserData()
            let otherData = {
                additionalTip: additionalTip,
                minimumspend: chargefromMinimum,
                gratuity: calculateGratuity(),
                saletax: calculateSalesTax(),
                memberEmail: memberDetails.email,
                parentEmail: userInfo?.email,
            }
            let metadata = { isPaymentFrom: "Purchase_Product", productInfo: JSON.stringify(getSelectedProductsMetadata()), otherInfo: JSON.stringify(otherData) }

            if ((chargefromMinimum / 100) <= parseFloat(userInfo.totalAmount)) {
                setPopupMessage('Payment Collected.')
                const nowTimestamp = Timestamp.fromDate(new Date());
                const currentTimestampInMilliseconds = Date.now();
                const currentTimestampInSeconds = Math.floor(currentTimestampInMilliseconds / 1000);
                let adata = {
                    "id": "",
                    "email": memberDetails?.email,
                    "parentEmail": userInfo?.email,
                    "intentId": '',
                    "amount": chargefromMinimum,
                    "metadata": metadata,
                    "products": adjustedProducts,
                    "currency": '',
                    "customer": "",
                    "description": BookingCategory.POS_FoodAndB,
                    "receipt_email": "",
                    "status": "success",
                    "createdDate": new Date().toISOString(),
                    "createdAt": nowTimestamp,
                    "createdBy": user.uid,
                    "intentCreatedAt": currentTimestampInSeconds,
                    //"receipt_url": intentRes.receipt_url
                }
                const addeddata = await OrderHistoryDataService.addOrderHistory(adata);
                let id = addeddata.id;
                adata.id = id;
                await OrderHistoryDataService.updateOrderHistory(id, adata);

                let data = JSON.stringify({
                    "type": BookingCategory.POS_FoodAndB,
                    "refId": id,
                    "creditType": "Debit",
                    "amount": chargefromMinimum / 100,
                    "userId": userInfo.id,
                    "currentUserId": memberDetails.id,
                    "createdBy": user.uid,
                    "isDrivingFeet": false
                });
                let config = {
                    method: 'post',
                    url: `${Base_Url}/createTransactionHistoryPOST`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                axios.request(config)
                    .then(async (response) => {
                        console.log(response)
                        setIsLoading(false)
                        setIsPaymentProcessing(false)
                        if (response) {
                            chargeGratuityFromCard()
                            setOpen(true)
                            setMsgText(`Payment Collected ${amount}. Reset & Collect New Payment`)
                            let aobj = {
                                id: id,
                                status: 'Success'
                            }
                            setPaymentIntentObj(aobj)
                            resetAndCollectNew()
                            if (selectedTabValue != "" && selectedTabValue != "NEW") {
                                deleteCartOrderFromFIR(selectedTabValue)
                            }
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        setIsPaymentProcessing(false)
                        adata.status = "failed"
                        OrderHistoryDataService.updateOrderHistory(id, adata);
                        alert(error)
                    });
            } else {
                setIsLoading(false)
                setIsPaymentProcessing(false)
                alert("No enough balanace, Use card on file")
            }
        }
    }

    const chargeOnFilePaymentIntentsAPI = async () => {
        if (selectedProducts && selectedProducts.length > 0) {
            setIsLoading(true)
            let amount = calculateTotalPriceWithGratuity()
            let chargefromCard = calculateTotalPriceForMinimum()

            let userInfo = getUserData()
            let otherData = {
                additionalTip: additionalTip,
                chargeCard: chargefromCard,
                gratuity: calculateGratuity(),
                saletax: calculateSalesTax(),
                memberEmail: memberDetails.email,
                parentEmail: userInfo?.email,
            }
            let metadata = { isPaymentFrom: "Purchase_Product", productInfo: JSON.stringify(getSelectedProductsMetadata()), otherInfo: JSON.stringify(otherData) }
            let data = JSON.stringify({
                "amount": chargefromCard,
                "metadata": metadata,
                "customerId": userInfo.stripeCustomerId ? userInfo.stripeCustomerId : "",
                "receiptEmail": userInfo.email,
                "description": "Charging you for your recent meal."
            });

            let config = {
                method: 'post',
                url: `${Base_Url}/chargeCardOnFile`,
                headers: {
                    'Content-Type': 'application/json',
                    'Acess-Control-Allow-Origin': '*',
                    'Authorization': `${user.accessToken}`,
                    'Accept': "application/json"
                },
                data: data
            };
            setIsPaymentProcessing(true)
            axios.request(config).then(async (response) => {
                setIsPaymentProcessing(false)
                if (response && response.data) {
                    let aobj = {
                        id: response.data.paymentIntentId,
                        status: response.data.status
                    }
                    checkIntentStatusAPI(response.data.paymentIntentId)
                    setPaymentIntentObj(aobj)
                    chargeGratuityFromCard()
                    setMsgText(`Payment Collected ${amount}. Reset & Collect New Payment`)
                    let sdata = {
                        "type": BookingCategory.POS_FoodAndB,
                        "refId": "",
                        "paymentIntentId": response.data.paymentIntentId,
                        "creditType": "Debit",
                        "amount": chargefromCard / 100,
                        "userId": userInfo.id,
                        "currentUserId": memberDetails.id,
                        "createdBy": user.uid,
                        "isDrivingFeet": false,
                        "isFromCardOrBank": true,
                        "createdAt": Timestamp.now()
                    };
                    const addeddata2 = await OrderHistoryDataService.addTransactionHistory(sdata);
                    let id2 = addeddata2.id;
                    sdata.id = id2;
                    await OrderHistoryDataService.updateTransactionHistory(id2, sdata);
                }
                setIsLoading(false)
            }).catch((error) => {
                setIsLoading(false)
                setIsPaymentProcessing(false)
                alert(error)
                setPaymentIntentObj(null)
                setReaderObj(null)
                trackException(JSON.stringify(error))
            });
        }
    }

    const chargeGratuityFromCard = async () => {
        let userInfo = getUserData()
        if (selectedProducts && selectedProducts.length > 0) {
            let amount = calculateGratuity()
            let msg = `Charging you for gratuity and sales tax on your recent meal. Gratuity: $${amount / 100}.`
            if (isSalesTaxOn) {
                let saleTaxAmount = (parseFloat(calculateSalesTax().toFixed(2)) * 100); //sats value
                msg = `Charging you for gratuity and sales tax on your recent meal. Gratuity: $${amount / 100}, Sales Tax: $${saleTaxAmount / 100}.`
                amount = amount + saleTaxAmount
            }
            if (amount && amount > 0) {
                let metadata = { isPaymentFrom: "Purchase_Product_Gratutity_Tax" }
                let data = JSON.stringify({
                    "amount": amount,
                    "metadata": metadata,
                    "customerId": userInfo.stripeCustomerId ? userInfo.stripeCustomerId : "",
                    "receiptEmail": userInfo.email,
                    "description": msg
                });
                let config = {
                    method: 'post',
                    url: `${Base_Url}/chargeCardOnFile`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Acess-Control-Allow-Origin': '*',
                        'Authorization': `${user.accessToken}`,
                        'Accept': "application/json"
                    },
                    data: data
                };
                axios.request(config).then((response) => {
                    setIsPaymentProcessing(false)
                    if (response && response.data) {
                    }
                }).catch((error) => {
                    alert(error)
                    trackException(JSON.stringify(error))
                });
            }

        }
    }

    async function chargeCardOnFile() {
        if (!checked) { return }
        let userInfo = getUserData()
        if (userInfo && userInfo.stripeCustomerId) {
            //TODO: Call API that take chage from file and add order history same as terminal device.
            chargeOnFilePaymentIntentsAPI()
        }
    }

    async function chargeWalletOnFile() {
        if (!checked) { return }

        let userInfo = getUserData()
        if (userInfo && userInfo.stripeCustomerId) {
            //TODO: Check for enaugh wallet balance
            chargeOnFileCreditPointAPI()
        }
    }

    const filterDataByC = (selectedFilter) => {
        if (selectedFilter === "All") {
            // If selected filter is "All", return the original list
            return productListArr;
        } else {
            // Filter the list based on the selected filter
            return productListArr.filter(product => product.metadata.C === selectedFilter);
        }
    };

    //Actions
    const handleFilterClick = (category) => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
        setFilterState({ selectedFilter: category });
    };

    const handleProductClick = (product) => {
        setSelectedProducts([...selectedProducts, { ...product, quantity: 1 }]);
    };

    const removeProductItem = (product) => {
        let updatedProducts = [...selectedProducts]
        const index = updatedProducts.findIndex(item => item.id === product.id);
        if (index !== -1) {
            updatedProducts.splice(index, 1);
        }
        setSelectedProducts(updatedProducts);
        const hasProductWithId = updatedProducts.some(item => item.id === product.id);
        if (!hasProductWithId) {
            // If no product with the given ID exists in updatedProducts, remove the ID from setSelectedProductIds
            setSelectedProductIds(selectedProductIds.filter((id) => id !== product.id));
        }
    }

    function resetAndCollectNew() {
        setPaymentIntentObj(null)
        setReaderObj(null)
        setMsgText('')
        setSelectedReaderId(null)
        setSelectedProducts([])
        setSelectedProductIds([]);
        setMemberDetails(null)
        checkUserHasParent(null)
        setAdditionalTip(0)
        setSalesTaxRate('7.45')
        setChecked(true)
    }

    const onClickNewOrder = () => {
        let tablistArrTemp = tabListArr;
        const isNewExists = tabListArr.some(item => item.id === "NEW");
        if (!isNewExists) {
            let obj = { id: `NEW`, label: `Tab ${tabListArr.length}` }
            tablistArrTemp.push(obj)
            setTabListArr([...tablistArrTemp])
            setSelectedTabValue(obj.id);
        }
    }

    async function onSaveOrderClick() {
        if (checked) {
            if (memberDetails) {
                setPopupMessage('Your order is successfully saved in your cart.')
                let obj = {
                    member: {
                        id: memberDetails.id,
                        memberNumber: memberDetails.memberNumber,
                        memberName: `${memberDetails.firstName} ${memberDetails.lastName}`
                    },
                    memberId: memberDetails.id,
                    selectedProducts: selectedProducts,
                    isCompleted: false,
                    isGuest: false,
                }
                if (selectedTabValue == "NEW") {
                    try {
                        const addeddata = await productCartDataService.addPOSCart(obj);
                        obj.id = addeddata.id;
                        await productCartDataService.updatePOSCart(addeddata.id, obj);
                        getCartOrderList()
                    } catch (error) {
                        trackException(JSON.stringify(error))
                    }
                } else {
                    try {
                        await productCartDataService.updatePOSCart(selectedTabValue, obj);
                        getCartOrderList()
                    } catch (error) {
                        trackException(JSON.stringify(error))
                    }
                }
                setOpen(true)
            } else {
                alert("Invalid Member Infomation.")
            }
        } else {
            let obj = {
                selectedProducts: selectedProducts,
                guestName: guestName,
                isGuest: true,
            }
            if (selectedTabValue == "NEW") {
                try {
                    const addeddata = await productCartDataService.addPOSCart(obj);
                    obj.id = addeddata.id;
                    await productCartDataService.updatePOSCart(addeddata.id, obj);
                    getCartOrderList()
                } catch (error) {
                    trackException(JSON.stringify(error))
                }
            } else {
                try {
                    await productCartDataService.updatePOSCart(selectedTabValue, obj);
                    getCartOrderList()
                } catch (error) {
                    trackException(JSON.stringify(error))
                }
            }
            setOpen(true)
        }
    }

    const isSimulateable = () => {
        if (readerObj) {
            return readerObj?.device_type?.includes("simulated") && paymentIntentObj?.id
                ? paymentIntentObj?.status !== 'succeeded' ? true : false
                : false;
        }
        return false;
    };

    const isCheckStausView = () => {
        if (readerObj) {
            return paymentIntentObj?.id ?
                paymentIntentObj?.status !== 'succeeded' ?
                    true :
                    false :
                false;
        }
        return false;
    };

    const isProcessable = () => {
        if (checked && memberDetails && memberDetails.firstName) {
            return selectedReaderId ? paymentIntentObj && paymentIntentObj.id !== "" ? false : true : false;
        } else if (!checked && guestName != "") {
            return selectedReaderId ? paymentIntentObj && paymentIntentObj.id !== "" ? false : true : false;
        } else {
            return false
        }
    };

    const isSaveOrderVisible = () => {
        return (selectedProducts && selectedProducts.length > 0 && selectedTabValue && selectedTabValue != "") ? true : false
    }
    const calculateTotalPrice = () => {
        return selectedProducts.reduce((total, product) => total + (product.price * product.quantity), 0);
        //return selectedProducts.reduce((total, product) => total + product.price, 0);
    };

    function calculatePercentage(value, percentage) {
        let decimal = percentage / 100;
        let result = value * decimal;
        return result;
    }

    const calculateGratuity = () => {
        const totalPrice = calculateTotalPrice();
        const gratuity = totalPrice * 0.2; // Calculate 20% gratuity
        if (isGratuityOn) {
            return gratuity;
        } else {
            return 0;
        }
    };
    const calculateSalesTax = () => {
        try {
            const totalPrice = calculateTotalPrice() / 100 //10  //1000
            let saletax = parseFloat(salesTaxRate)
            let result = calculatePercentage(totalPrice, saletax);
            if (isSalesTaxOn) {
                return result;
            } else {
                return 0;
            }
        } catch (error) {
            return 0;
        }
    };

    const calculateTotalPriceWithGratuity = () => {
        const totalPrice = calculateTotalPrice();
        const gratuity = totalPrice * 0.2; // Calculate 20% gratuity
        const tips = additionalTip * 100;
        if (isGratuityOn && isSalesTaxOn) {
            const saleTaxTotal = calculateSalesTax() * 100; //Convert to sats
            return totalPrice + gratuity + saleTaxTotal + tips;
        } else if (isGratuityOn) {
            return totalPrice + gratuity + tips;
        } else if (isSalesTaxOn) {
            const saleTaxTotal = calculateSalesTax() * 100;//Convert to sats
            return totalPrice + saleTaxTotal + tips;
        } else {
            return totalPrice + tips;
        }
    };
    const calculateTotalPriceForMinimum = () => {
        const totalPrice = calculateTotalPrice();
        const tips = additionalTip * 100;
        return totalPrice + tips;
    };

    useEffect(() => {
        if (selectedReaderId) {
            setSelectedProductIds([]);
        }
    }, [selectedReaderId]);

    const onChange = React.useCallback(
        (ev) => {
            setChecked(ev.currentTarget.checked);
        },
        [setChecked]
    );

    const onChangeGratuity = React.useCallback(
        (ev) => {
            setIsGratuityOn(ev.currentTarget.checked);
        },
        [setIsGratuityOn]
    );

    const onChangeSalesTax = React.useCallback(
        (ev) => {
            setIsSalesTaxOn(ev.currentTarget.checked);
        },
        [setIsSalesTaxOn]
    );

    return (
        <Layout>
            <div className={styles.content} style={!isSidebarOpen ? { width: "97vw" } : { width: "calc(100vw - 320px)" }}>
                <h2 className={"pos_header"}>Collecting Payments with Stripe Terminal</h2>
                <div className={"pos-instructions__card-layout"}>
                    <div className="pointer" style={{ width: '100%' }} onClick={() => { setIsShowInstruction(!isShowInstruction) }}>
                        <div className={"d-flex"} style={{ justifyContent: 'space-between' }}>
                            <div className={"d-flex"}>
                                <InstructionsSVG />
                                <span className={"pos-instructions-card__header"}>INSTRUCTIONS</span>
                            </div>
                            {
                                isShowInstruction ? <UPArrowSVG /> : <DownArowSVG />
                            }
                        </div>
                    </div>
                    {
                        isShowInstruction &&
                        <>
                            <Divider className={"pos-instructions-card__hr"} />
                            <div>
                                <div className={"d-flex "}>
                                    <div className={'pos-instructions-card__index vertical-center'}>1</div>
                                    <div className={"pos-instructions-card_steps"}>Select a reader and amount for the
                                        transaction.
                                    </div>
                                </div>
                                <div className={"d-flex vertical-center"} style={{ marginTop: "16px" }}>
                                    <div className={'pos-instructions-card__index vertical-center'}>2</div>
                                    <div className={"pos-instructions-card_steps"}>Click Process - Check POS and Swipe Card
                                    </div>
                                </div>
                                <div className={"d-flex vertical-center"} style={{ marginTop: "16px" }}>
                                    <div className={'pos-instructions-card__index vertical-center'}>3</div>
                                    <div className={"pos-instructions-card_steps"}>Once POS show success then Click on Check
                                        status
                                    </div>
                                </div>
                            </div>
                            <Divider className={''} style={{ marginTop: "6px", marginBottom: "7px" }} />
                            <div className={""} style={{ display: "flex" }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><InfoSVG /></div></div>
                                <div className={"pos-instructions-card_info"}>Reader Status & Payment Intent status success mean
                                    Payment collected
                                </div>
                            </div>
                            <Divider className={''} style={{ marginTop: "4px", marginBottom: "5px" }} />
                            <div className={" "} style={{ display: "flex" }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ErrorWithWarningSVG /></div></div>
                                <div className={"pos-instructions-card_Warning"}>Please don’t reset until Payment status update
                                    to fail or success
                                </div>
                            </div>
                        </>
                    }

                </div>

                <div className={'d-flex'} style={{ marginTop: 20 }}>
                    <TabList selectedValue={selectedTabValue} onTabSelect={onTabSelect}>
                        {tabListArr && tabListArr.map(tab => (
                            <Tab key={tab.id} value={tab.id}>
                                {tab.label}
                            </Tab>
                        ))}
                    </TabList>
                    <div
                        onClick={() => { onClickNewOrder() }}
                        style={{ marginLeft: 12, marginTop: 2, marginBottom: 2 }} className={"pointer d-flex vertical-center"}>
                        <div><AddGreenSVG /></div>
                        <span className={"pos-opennewtabfont"}>
                            OPEN NEW TAB
                        </span>
                    </div>
                </div>
                <div style={{ marginLeft: '12px', height: 1, backgroundColor: '#E6E6E6', width: '100%' }} />

                <div style={{ marginTop: '20px' }} />
                <div style={{ cursor: 'pointer' }} onClick={() => {
                    setChecked(!checked);
                }}>
                    <Switch
                        style={{ marginLeft: -8 }}
                        checked={checked}
                        label={`Is WAREHOUSE Member?`}
                        labelPosition="before"
                        onChange={onChange}
                    />
                </div>
                {
                    checked ?
                        <div className={"d-flex"} style={{ alignItems: "center", marginTop: "20px" }}>
                            {filterUserList?.length >= 0 &&
                                <div style={{ marginTop: '0px', marginRight: '20px' }}>
                                    <label className={"pos-field__label"}>Search Member</label>
                                    <Combobox multiselect={false}
                                        className={"pos-input__field"}
                                        style={{ height: 48, marginTop: '8px' }}
                                        //placeholder={linkUserPlaceholder}
                                        onOptionSelect={(e, data) => {
                                            setMemberNumber(data?.optionValue)
                                        }}
                                    >
                                        {filterUserList?.map((option) => (
                                            <Option key={option.id} value={option.memberNumber}>
                                                {`${option.firstName} ${option.lastName}`}
                                            </Option>
                                        ))}
                                    </Combobox>
                                </div>
                            }
                            <div>
                                <label className={"pos-field__label"}> Member Number</label>
                                <Input onbl value={memberNumber} className={"pos-input__field"}
                                    style={{ marginTop: "8px" }}
                                    placeholder={"Enter Number"}
                                    onChange={(ev, newVal) => setMemberNumber(newVal.value)} />
                            </div>
                            <div >
                                <label className={"pos-field__label"} style={{ visibility: "hidden" }}> .</label>
                                <button className={" pos-verify__btn pointer primaryHover"} shape="circular" style={{ marginTop: "8px" }} onClick={() => {
                                    getUserDetails()
                                }}>Verify
                                </button>
                            </div>

                            {userListByLastName?.length >= 2 &&
                                <div>
                                    <Combobox multiselect={false}
                                        className={"pos-input__field"}
                                        style={{ height: 50, marginTop: 24, marginLeft: "16px" }}
                                        //placeholder={linkUserPlaceholder}
                                        onOptionSelect={(e, data) => {
                                            setMemberNumber(data?.optionValue)
                                        }}
                                    >
                                        {userListByLastName?.map((option) => (
                                            <Option key={option.id} value={option.memberNumber}>
                                                {`${option.firstName} ${option.lastName}`}
                                            </Option>
                                        ))}
                                    </Combobox>
                                </div>}
                        </div>
                        :
                        <div className={"d-flex"} style={{ alignItems: "center", marginTop: "20px" }}>
                            <div>
                                <label className={"pos-field__label"}>Enter Full Name</label>
                                <Input onbl value={guestName} className={"pos-input__field"}
                                    style={{ marginTop: "8px" }}
                                    placeholder={"Enter Full Name"}
                                    onChange={(ev, newVal) => setGuestName(newVal.value)} />
                            </div>
                        </div>
                }

                {checked && memberDetails && memberDetails?.firstName && (
                    <div style={{ marginTop: 10, width: '50%' }}>
                        <Label style={{ marginRight: 20, }}>Email: {memberDetails?.email}</Label>
                    </div>)
                }
                <div className={"pos-select-product_container d-flex vertical-center"}>
                    <div><SelectProductSVG /></div>
                    <span className={"pos-select-product__header"}>
                        Select Products
                    </span>
                </div>

                <div className="d-flex pos-select-product-container">
                    {categoryList && categoryList.length > 0 && categoryList.map((category, index) => (
                        <div
                            key={index}
                            className={`pos-select-product-hover pos-select-product__filter d-flex pointer ${filterState.selectedFilter === category ? "selected-filter" : ""}`}
                            onClick={() => handleFilterClick(category)}
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                {/* Here you can render different icons based on category */}
                                {category === 'All' ? <SelectProductFilterAll /> : <SelectProductFilterAll />}
                                {/* Add other category icons as needed */}
                            </div>
                            <div>{category}</div>

                        </div>
                    ))}
                </div>

                {!isLoading && <div style={{ marginTop: 32 }}>
                    <ProductList isReset={selectedReaderId ? false : true} data={filterDataByC(filterState.selectedFilter)}
                        onProductClick={handleProductClick} adjustedProducts={adjustedProducts} selectedProductIds={selectedProductIds} toggleProductSelection={toggleProductSelection} />
                </div>}
                {
                    isLoading && <div className={'d-flex '} style={{ marginTop: 32 }}>
                        {[...Array(6)].map(
                            (d, i) => (
                                <div className={"pos_loading_card__box"} key={i}>
                                    <SkeletonItem shape="rectangle" size={128} />
                                    <Divider />
                                    <div style={{ marginTop: "10px", width: "120px", marginLeft: "4px" }}>
                                        <SkeletonItem shape="rectangle" size={12} />
                                    </div>
                                    <div style={{ marginTop: "3px", width: "50px", marginLeft: "4px" }}>
                                        <SkeletonItem shape="rectangle" size={12} />
                                    </div>
                                    <div style={{ marginTop: "30px", width: "40px", marginLeft: "4px" }}>
                                        <SkeletonItem shape="rectangle" size={12} />
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                }

                {selectedProducts?.length > 0 &&
                    <div className={"d-flex"} style={{ marginTop: "49px" }} >
                        <div className={"pos-selected-list-table__container "}>
                            <div className={"d-flex"}>
                                <div className={"pos-selected-list-table__header"} style={{ marginLeft: '3%', width: "37%" }}>PRODUCT</div>
                                <div className={"pos-selected-list-table__header d-flex justify-center"} style={{ width: "20%" }}>QTY</div>
                                <div className={"pos-selected-list-table__header d-flex justify-center"} style={{ width: "20%" }}>PRICE</div>
                                <div className={"pos-selected-list-table__header d-flex justify-center"} style={{ width: "20%" }}>Total</div>
                            </div>
                            <Divider />
                            {adjustedProducts?.map((product, index) => (
                                <div className={"d-flex vertical-center"} key={`${index}-${product.id}`}>
                                    <div className={"pos-selected-list-table__header "}
                                        style={{ width: "40%", alignItems: "center", display: "flex" }}>
                                        <img src={!!product?.images[0] ? product?.images[0] : placeholderImageUrl} height={"48px"} width={"40px"} alt={"placeholderImageUrl"}
                                            className={"pos-selected-list-table__image"} />
                                        <div className={"pos-selected-list-table__item-label"}>{product?.productName}</div>
                                    </div>
                                    <div className={"pos-selected-list-table__header d-flex  justify-center vertical-center"}
                                        style={{ width: "20%" }}>
                                        <div
                                            onClick={() => {
                                                removeProductItem(product)
                                            }}
                                            className={"pos-selected-list-table__add-price d-flex justify-center vertical-center pointer pos-qnt-decrement "}
                                            style={{ background: "#BDBDBD" }}>
                                            -
                                        </div>
                                        <div className={"pos-selected-list-table__price pos-selected-list-table__counts"}>
                                            <div className={"pos-price__label"}>{product?.quantity}</div>
                                        </div>
                                        <div
                                            className={"pos-selected-list-table__add-price d-flex justify-center vertical-center pointer pos-qnt-increment"}
                                            onClick={() => {
                                                const newQuantity = parseInt(product?.quantity) + 1;
                                                if (!isNaN(newQuantity) && newQuantity > 0) {
                                                    handleProductClick(product)
                                                }
                                            }}
                                            style={{ background: "#0A4" }}>+
                                        </div>
                                    </div>
                                    <div className={" pos-selected-list-table__price vertical-center d-flex justify-center pos-price__label"}
                                        style={{ width: "20%" }}>${product.price / 100}
                                    </div>
                                    <div className={" pos-selected-list-table__price vertical-center d-flex justify-center pos-price__label"}
                                        style={{ width: "20%" }} >${(product.price * product.quantity) / 100}
                                    </div>
                                </div>
                            ))}

                        </div>
                        <div className={"pos-payment-summary"}>
                            <div className={"pos-payment-summary__header-tag  d-flex vertical-center"} style={{ justifyContent: 'space-between' }}>
                                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                    <PaymentSummrySVG />
                                    <div className={"pos-payment-summary__header"}>
                                        Payment Summary
                                    </div>
                                </div>
                                <div style={{ height: '30px', marginTop: '-8px' }}>
                                    <div style={{ cursor: 'pointer' }} onClick={() => {
                                        setIsGratuityOn(!isGratuityOn)
                                    }}>
                                        <Switch
                                            checked={isGratuityOn}
                                            size={'small'}
                                            label={`Gratuity`}
                                            labelPosition="before"
                                            onChange={onChangeGratuity}
                                        />
                                    </div>
                                </div>
                                <div style={{ height: '30px', marginTop: '-8px' }}>
                                    <div style={{ cursor: 'pointer' }} onClick={() => {
                                        setIsSalesTaxOn(!isSalesTaxOn)
                                    }}>
                                        <Switch
                                            checked={isSalesTaxOn}
                                            label={`Sales Tax`}
                                            labelPosition="before"
                                            onChange={onChangeSalesTax}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Divider />
                            <div>
                                <div className={"d-flex justify-space-between"}>
                                    <div className={"pos-payment-summary__data"}>
                                        Total:
                                    </div>
                                    <div className={" pos-price__label"} style={{ fontSize: "16px", paddingRight: 24, paddingTop: "22px" }}>
                                        ${calculateTotalPrice() / 100}
                                    </div>
                                </div>
                                {
                                    isGratuityOn ?
                                        <div className={"d-flex justify-space-between"}>
                                            <div className={"pos-payment-summary__data pos-price__label"}>
                                                Gratuity:
                                            </div>
                                            <div className={" pos-price__label"} style={{ fontSize: "16px", paddingRight: 24, paddingTop: "22px" }}>
                                                ${calculateGratuity() / 100}
                                            </div>
                                        </div> :
                                        <div />
                                }
                                {
                                    isSalesTaxOn ?
                                        <div className={"d-flex justify-space-between"}>
                                            <div className={"pos-payment-summary__data pos-price__label"}>
                                                Sales Tax Rate %:
                                            </div>
                                            <div className={"pos-payment-summary__data"} style={{ marginButton: "22px" }}>
                                                <input className={"pos-payment-summary__input"}
                                                    value={salesTaxRate}
                                                    onChange={(ev) => {
                                                        ev.target.value >= 0 ? setSalesTaxRate(ev.target.value) : setSalesTaxRate(7.45)
                                                    }}
                                                    placeholder={"Enter Additional Tip"} />
                                            </div>
                                        </div> :
                                        <div />
                                }
                                {
                                    isSalesTaxOn ?
                                        <div className={"d-flex justify-space-between"}>
                                            <div className={"pos-payment-summary__data pos-price__label"}>
                                                Sales Tax:
                                            </div>
                                            <div className={" pos-price__label"} style={{ fontSize: "16px", paddingRight: 24, paddingTop: "22px" }}>
                                                ${calculateSalesTax()}
                                            </div>
                                        </div> :
                                        <div />
                                }
                                <div className={"d-flex justify-space-between"}>
                                    <div className={"pos-payment-summary__data"}>
                                        Additional Tip:
                                    </div>
                                    <div className={"pos-payment-summary__data"} style={{ marginButton: "22px" }}>
                                        <input className={"pos-payment-summary__input"}
                                            value={additionalTip}
                                            onChange={(ev) => {
                                                ev.target.value >= 0 ? setAdditionalTip(ev.target.value) : setAdditionalTip(0)
                                            }}
                                            placeholder={"Enter Additional Tip"} />
                                    </div>
                                </div>
                                <Divider style={{ paddingTop: "10px" }} />
                                <div className={"d-flex justify-space-between"} style={{ paddingBottom: '10px' }}>
                                    <div className={"pos-payment-summary__data"} style={{ paddingTop: "10px" }}>
                                        Grand Total:
                                    </div>
                                    <div className={" pos-price__label"} style={{ paddingTop: "10px", fontSize: "16px", paddingRight: 24 }}>
                                        ${calculateTotalPriceWithGratuity() / 100}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    paymentIntentObj && paymentIntentObj.id && paymentIntentObj.status !== 'succeeded' ?
                        <div style={{ marginTop: 10 }}>
                            <Text size={500}>Swipe or tap card now.</Text>
                        </div> : <div />
                }
                {
                    paymentIntentObj && paymentIntentObj.id && paymentIntentObj.status === 'succeeded' ?
                        <div style={{ marginTop: 10 }}>
                            <Text size={500}>Payment Collected. Reset & Collect New Payment</Text>
                        </div> : <div />
                }

                <Popover open={open} onOpenChange={handleOpenChange}>
                    <PopoverTrigger disableButtonEnhancement>
                        {/* <Button></Button> */}
                    </PopoverTrigger>
                    <PopoverSurface tabIndex={-1}>
                        <ExampleContent popupMessage={popupMessage} />
                    </PopoverSurface>
                </Popover>

                <Button className={"pos-saveorder__btn pointer primaryHover"}
                    shape="circular"
                    appearance="primary"
                    disabled={!isSaveOrderVisible()}
                    style={{ marginTop: "8px", opacity: !isSaveOrderVisible() ? 0.4 : 1 }}
                    onClick={() => {
                        onSaveOrderClick()
                    }}>Save Order</Button>
                <div className="pos-paybutton__container">
                    <div className={"pos-payment-summary__header"}>
                        Make Payment
                    </div>
                    <div style={{ marginTop: '20px' }} className={"d-flex"}>
                        {isPaymentProcessing ? (
                            // Render loader when payment is processing
                            <div style={{ marginRight: 20 }}>
                                <Spinner />
                            </div>
                        ) : (
                            <Button
                                disabled={false}
                                className={"pos-secondary__btn pos-button-typo"}
                                style={{ marginRight: 20, opacity: 1 }}
                                shape="circular"
                                appearance="primary"
                                onClick={chargeCardOnFile}>
                                CHARGE CARD ON FILE
                            </Button>
                        )}

                        {isPaymentProcessing ? (
                            // Render loader when payment is processing
                            <div style={{ marginRight: 20 }}>
                            </div>
                        ) : (

                            checked && memberDetails && getMemberBalance() > 0 ?
                                <div className={"pos-secondary__btn pos-button-typo"} style={{ flexDirection: 'row' }}>
                                    <div
                                        disabled={false}
                                        style={{ marginRight: 20, opacity: 1 }}
                                        shape="circular"
                                        appearance="primary"
                                        onClick={chargeWalletOnFile}>
                                        {'Use minimum balance'.toUpperCase()}
                                    </div>
                                    <Label style={{ fontSize: 12, color: '#8792A2' }}>${getMemberBalance()}</Label>
                                </div> : <div />
                        )}

                    </div>
                    <div style={{ marginTop: 12, marginBottom: 12 }}>
                        <Label style={{ fontSize: 20, color: '#8792A2' }}>------OR------</Label>
                    </div>
                    <div>
                        <label className={"pos-field__label"}>Pay Using Terminal Device</label>
                        <Dropdown
                            placeholder={"Select Device"}
                            className={"pos-dropdown__field"}
                            aria-labelledby={`1-outline`}
                            style={{ marginTop: "8px" }}
                            onOptionSelect={(ev, data) => {
                                setSelectedReaderId(data.selectedOptions[0])
                            }}
                        >
                            {readerList?.map((option) => (
                                <Option key={option.id} value={option.id}>
                                    {`${option.label}`}
                                </Option>
                            ))}
                        </Dropdown>
                    </div>
                    <div className={"d-flex justify-space-between vertical-center"}>
                        <div style={{ marginTop: 12, display: "flex" }}>
                            <Button className={"pos-primary__btn primaryHover "}
                                disabled={!isProcessable()}
                                style={{ marginRight: 20, opacity: !isProcessable() ? 0.4 : 1 }}
                                shape="circular"
                                appearance="primary"
                                onClick={() => {
                                    processPaymentIntentsAPI(selectedReaderId)
                                }}>
                                <span className={"d-flex vertical-center pos-button-typo"}><SendTerminalSVG />SEND TERMINAL</span>
                            </Button>

                            {/* <Button disabled={!isCapturable()} style={{ marginRight: 20 }} shape="circular" appearance="primary" onClick={() => { capturePaymentAPI(paymentIntentObj.id) }}>Capture</Button> */}
                            <Button className={"pos-primary__btn pos-button- primaryHover"}
                                disabled={!isCheckStausView()}
                                style={{ marginRight: 20, opacity: !isCheckStausView() ? 0.4 : 1 }} shape="circular"
                                appearance="primary"
                                onClick={() => {
                                    checkIntentStatusAPI(paymentIntentObj?.id)
                                }}>CHECK STATUS</Button>

                            <Button className={"pos-secondary__btn pos-button-typo "}
                                disabled={!isSimulateable()}
                                style={{ marginRight: 20, opacity: !isSimulateable() ? 0.4 : 1 }} shape="circular"
                                appearance="primary"
                                onClick={() => {
                                    simulatePresentingPaymentAPI(selectedReaderId)
                                }}>SIMULATE PAYMENT</Button>

                            <Button className={"pos-secondary__btn pos-button-typo"}
                                disabled={readerObj ? false : true}
                                style={{ marginRight: 20, opacity: readerObj ? 1 : 0.4 }} shape="circular"
                                appearance="primary" onClick={() => {
                                    readersCancelActionAPI(readerObj?.id)
                                }}>CANCEL</Button>
                        </div>
                    </div>

                </div>
                <div>
                    <div className={"pos-payment-details__typo vertical-center d-flex"} >
                        <PaymentIntentIDSVG /><p style={{ paddingLeft: 8 }}>Payment Intent ID:</p>
                        <span>{paymentIntentObj?.id}</span>
                    </div>
                    <div className={"pos-payment-details__typo vertical-center d-flex"}>
                        <PaymentIntentStatusSVG /> <p style={{ paddingLeft: 8 }}>Payment Intent status:</p>
                        <span>{paymentIntentObj?.status}</span>
                    </div>
                    <div className={"pos-payment-details__typo vertical-center d-flex"}>
                        <ReaderStatusSVG /><p style={{ paddingLeft: 8 }}>Reader Status:</p>
                        <span>{readerObj?.action?.status}</span>
                    </div>
                </div>
                <div style={{ marginTop: 28 }} className={"reset-and-collect-payment"}>
                    <Button className={"pos-secondary__btn pos-button-typo"} style={{ marginRight: 20 }} shape="circular" appearance="primary"
                        onClick={() => {
                            resetAndCollectNew()
                        }}>RESET & COLLECT NEW PAYMENT</Button>
                </div>
                <div style={{ marginTop: 20 }}>
                    <Label>{msgText}</Label>
                </div>
            </div>
        </Layout>
    )
}

export default POSOrder;

function adjustProductQuantity(products) {
    const mergedProducts = products.reduce((acc, curr) => {
        const existingProductIndex = acc.findIndex(p => p.id === curr.id);
        if (existingProductIndex !== -1) {
            // Create a new object with updated quantity
            const updatedProduct = {
                ...acc[existingProductIndex],
                quantity: acc[existingProductIndex].quantity + curr.quantity
            };
            // Replace the existing product with the updated one
            acc[existingProductIndex] = updatedProduct;
        } else {
            // Add the product if it doesn't exist in the accumulator array
            acc.push(curr);
        }
        return acc;
    }, []);

    return mergedProducts;
}
const ExampleContent = ({ popupMessage }) => {
    return (
        <div>
            <div>{popupMessage}</div>
        </div>
    );
};
