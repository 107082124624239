import {
    Button, Label, Table, TableBody,
    TableCell,
    TableCellActions,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles, shorthands
} from "@fluentui/react-components";
import { DeleteFilled, DeleteRegular, EditFilled, EditRegular, bundleIcon } from "@fluentui/react-icons";
import { collection, getDocs, query, where } from "firebase/firestore";
import debounce from 'lodash/debounce';
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import CustomSearchInput from "./components/CustomSearchInput";
import DeleteEventDialog from "./components/DeleteEventDialog";
import SpaceClosuresForm from "./components/Forms/SpaceClosuresForm";
import Layout from "./components/Layout";
import { auth, db, trackException } from "./services/firebase";
import roomsDataService from "./services/roomsDataService";
import { PlusSVG } from "./static/svgs";
import moment from "moment";

const EditIcon = bundleIcon(EditFilled, EditRegular);
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    }
});

const columns = [
    { columnKey: "spaceType", label: "Space Type" },
    { columnKey: "closingDate", label: "Closing Date" },
    { columnKey: "startClosingDate", label: "Start Closing Date" },
    { columnKey: "endClosingDate", label: "End Closing Date" },
    { columnKey: "reasonforClosure", label: "Reason for Closure" },
];

function SpaceClosures() {
    const styles = useStyles();
    const [user, loading, error] = useAuthState(auth);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState();
    const [allrooms, setAllRooms] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentuserId, setCurrentuserId] = React.useState('');
    const [closureHours, setClosureHours] = useState([]);
    const [filteredClosureHours, setFilteredClosureHours] = useState([]);


    const [open, setOpen] = React.useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const navigate = useNavigate();
    const openForm = (selectedItem) => {
        setIsFormOpen(true);
        setSelectedRoom(selectedItem);
    }

    const fetchUserNameAndRooms = async () => {
        try {
            if (!!user) {
                const userquery = query(collection(db, "Users"), where("email", "==", user?.email));
                const userdoc = await getDocs(userquery);
                const currentuser = userdoc.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                setCurrentuserId(currentuser[0].id);

                if (userdoc.docs.length > 0) {
                    const roomsQuery = await roomsDataService.getAllRooms();
                    let roomsArr = roomsQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                    setAllRooms(roomsArr);

                    const closureHoursQuery = await roomsDataService.getClosureHours();
                    let closureHoursArr = closureHoursQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    // Filter for only items with type 'SpaceClosure'
                    let spaceClosures = closureHoursArr.filter(item => item.type === "SpaceClosure");
                    // Update the state with filtered data
                    setClosureHours(spaceClosures);
                    setFilteredClosureHours(spaceClosures);
                } else {
                    console.log('no access');
                }
            }
        } catch (err) {
            trackException(JSON.stringify(err))
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };


    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        fetchUserNameAndRooms();
    }, [user, loading]);

    const openDeleteForm = (selectedItem) => {
        setIsDeleteDialogOpen(true);
        setSelectedRoom(selectedItem);
        // fetchUserNameAndEvents();
    }

    const DeleteRoom = async () => {
        roomsDataService.deleteClosureHours(selectedRoom.id);
        setIsDeleteDialogOpen(false);
        fetchUserNameAndRooms();
    };

    // Debounce the handleSearch function
    const debouncedSearch = debounce(search => {
        const lowerSearch = search.toLowerCase();
        const filtered = closureHours.filter(event => {
            return (
                event.name.toLowerCase().includes(lowerSearch)
            )
        });
        setFilteredClosureHours(filtered);
    }, 300); // Adjust the debounce delay as needed

    const handleSearch = event => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };
    const handleClear = () => {
        setSearchTerm('');
        debouncedSearch('');
    };

    return (
        <Layout>
            <div className={styles.content}>
                <div className={"d-flex justify-space-between"}>
                    <Button icon={<PlusSVG />} className={"primary-btn"} shape="circular" appearance="primary" onClick={() => openForm(undefined)}>{'NEW Space Closure'.toUpperCase()}</Button>
                    <CustomSearchInput
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={handleClear}
                    />
                </div>
                <div className={"table-layout"}>
                    <Table aria-label="Table with cell actions">
                        <TableHeader className={"list-row"}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell key={column.columnKey}>
                                        <span className={"listHeaderText"}>{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredClosureHours && filteredClosureHours.length > 0 && filteredClosureHours.map((item) =>
                                <TableRow key={item.id}>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            {item.roomType}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            {
                                                item.dateRangeOn ? <div /> :
                                                    <Label title={moment(item.closingDate).format('DD MMM YYYY')}>
                                                        {moment(item.closingDate).format('DD MMM YYYY')}
                                                    </Label>
                                            }

                                        </TableCellLayout>
                                    </TableCell>

                                    {/* Start Closing Date */}
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            {
                                                item.dateRangeOn ?
                                                    <Label title={moment(item.startDate).format('DD MMM YYYY')}>
                                                        {moment.utc(item.startDate).format('DD MMM YYYY HH:mm')}
                                                    </Label> :
                                                    <div />
                                            }
                                        </TableCellLayout>
                                    </TableCell>

                                    {/* End Closing Date */}
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            {
                                                item.dateRangeOn ?
                                                    <Label title={moment(item.endDate).format('DD MMM YYYY')}>
                                                        {moment.utc(item.endDate).format('DD MMM YYYY HH:mm')}
                                                    </Label> :
                                                    <div />
                                            }
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            <Label title={item.description}>{item.reasonClosure}</Label>
                                        </TableCellLayout>
                                        <TableCellActions>
                                            <Button
                                                icon={<EditIcon />}
                                                appearance="subtle"
                                                aria-label="Edit"
                                                onClick={() => openForm(item)}
                                            />
                                            <Button
                                                icon={<DeleteIcon />}
                                                appearance="subtle"
                                                aria-label="Delte"
                                                onClick={() => openDeleteForm(item)}
                                            />
                                        </TableCellActions>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
            <SpaceClosuresForm isOpen={isFormOpen} setIsOpen={(val) => setIsFormOpen(val)} selectedRoom={selectedRoom}
                updateGrid={() => { fetchUserNameAndRooms() }} createdBy={currentuserId} roomType={allrooms}></SpaceClosuresForm>
            <DeleteEventDialog isDeleteDialogOpen={isDeleteDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen} DeleteEvent={DeleteRoom} text={'Space Closure'} />
        </Layout>
    )
}

export default SpaceClosures;