import {
    MenuList,
    makeStyles,
    mergeClasses,
    shorthands
} from "@fluentui/react-components";
import {
    DrawerBody,
    DrawerInline
} from "@fluentui/react-components/unstable";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { JSONManipulator } from "../static/commonFunctions";
import { sidebarStaticObject } from "../static/staticObject";
import {
    AnnouncementsSidebarSVG,
    AppSettingsSidebarSVG,
    AttractionsSidebarSVG,
    CoachSidebarSVG,
    DrivingRoadsSidebarSVG,
    EventSidebarSVG,
    GroupsSidebarSVG,
    LogOutSVG,
    MembersSidebarSVG,
    MessageUserSidebarSVG,
    POSSidebarSVG,
    ProvidersSidebarSVG,
    RentalSidebarSVG,
    RestaurantsSidebarSVG,
    RoomSidebarSVG,
    ServiceTypesSidebarSVG,
    ServicesSidebarSVG,
    VehiclesSidebarSVG,
    VehicleKeysSidebarSVG,
    VehicleRequestsSVG,
    POSHistorySVG,
    POSProductSVG,
    SpaceClosuresSVG
} from "../static/svgs";
import { logout } from "../services/firebase";
import { useSelector } from "react-redux";
import { getUserAccessPermission, getUserData } from "../slices";

const useStyles = makeStyles({
    root: {
        // backgroundColor: "#ada8a8",
        backgroundColor: "#202020",
        ...shorthands.gridArea("menu"),
        color: "#FFFFF",
    },
    wapleftnav: {
        height: "calc(100vh - 48px) !important",
        overflow: "auto !important",
        width: '270px',
        scrollbarWidth: 'none'
    },
    wapleftnavClose: {
        height: "calc(100vh - 48px) !important",
        overflow: "auto !important",
        width: 0,
        scrollbarWidth: 'none'
    },
    apptitle: {
        ...shorthands.margin('0 auto')
    },
    menuemargintop: {
        ...shorthands.margin('5px', '0px')
    },
    parentspan: {
        ...shorthands.padding('0px', '24px')
    },
    navHeader: {
        ...shorthands.margin('0 auto')
    },
    noUnderline: {
        borderRadius: '28px !important',
        background: '#333',
        width: '0px',
        height: '32px',
        flexShrink: 0
    },
    noUnderlineClose: {
        borderRadius: '28px !important',
        background: '#333',
        width: 0,
        height: '32px',
        flexShrink: 0
    },
    mainMenu: {
        marginTop: '10px',
        color: '#F2EFEF',
        fontFamily: 'Raleway',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
        textAlign: 'left',
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
    },
    subMenu: {
        fontFamily: 'Raleway',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        textAlign: 'left',
        color: "#FFFFFF",
        textDecoration: "none !important",
        padding: "10px",
        marginTop: "12px",
        paddingLeft: "12px",
        cursor: "pointer",
        alignItems: 'center',
        display: 'flex',
        gap: '8px !important'
    },
    activeSidebar: {
        borderRadius: '28px !important',
        background: '#333 !important',
        marginLeft: "-20px !important",
        height: '32px !important',
        flexShrink: 0
    },
    // logoutButton: {
    //     borderTop: '1px solid #3A3A3A',
    //     background: '#202020',
    //     display: 'flex',
    //     padding: '16px 0px',
    //     gap: '20px',
    //     color: '#FF9058',
    //     fontFamily: 'Raleway',
    //     fontSize: '16px',
    //     fontStyle: 'normal',
    //     fontWeight: 600,
    //     lineHeight: '20px'
    // },
    logoutButton: {
        width: '100%',
        display: 'flex',
        padding: '16px 0px',
        gap: '20px',
        color: '#FF9058',
        fontFamily: 'Raleway',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px'
    }

});

const desiredOrder = ["Admin", "Community", "Reservations", "Locations", "People", "POS", "AppSetting"];

function LeftNav() {
    const styles = useStyles();
    const layoutState = useSelector(state => state.layout)
    const navigate = useNavigate()
    const userData = useSelector(getUserData);
    let accessPermission = userData.accessPermission.slice(); // Create a shallow copy
    // Sort the accessPermission array according to the desired order
    accessPermission.sort((a, b) => {
        return desiredOrder.indexOf(a) - desiredOrder.indexOf(b);
    });

    const [checkedValues, setCheckedValues] = React.useState({
        navigation: [""]
    });
    const oldSidebarState = JSON.parse(localStorage.getItem("sidebarState"))
    const [sidebarState, setSidebarState] = useState(oldSidebarState ? oldSidebarState :
        sidebarStaticObject)
    React.useEffect(() => {
        localStorage.setItem("sidebarState", JSON.stringify(sidebarState))
    }, [sidebarState])

    const UpIcon = (sidebarState) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
                <path d="M1 6.66671L6.86713 1.45148C6.9429 1.38413 7.0571 1.38413 7.13287 1.45148L13 6.66671"
                    stroke="#8B8B8B" stroke-width="1.5" stroke-linecap="round" />
            </svg>)
    }
    const DownIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
                <path d="M13 1.33342L7.13287 6.54864C7.0571 6.616 6.9429 6.616 6.86713 6.54864L1 1.33342" stroke="#8B8B8B"
                    stroke-width="1.5" stroke-linecap="round" />
            </svg>
        )
    }
    const onChange = (
        e,
        { name, checkedItems }
    ) => {
        setCheckedValues((s) => ({ ...s, [name]: checkedItems }));
    };
    function setSubMenuActiveToFalse(menu) {
        for (let menuItem in menu) {
            if (menu.hasOwnProperty(menuItem) && menu[menuItem].hasOwnProperty('subMenu')) {
                menu[menuItem].subMenu.forEach(subMenuItem => {
                    subMenuItem.isActive = false;
                });
            }
        }
        return menu;
    }
    const checkActiveStatus = (mainKey, key) => {
        let findIndexOfKey = sidebarState[mainKey]?.subMenu?.findIndex((d) => d?.key === key);

        return sidebarState[mainKey]?.subMenu[findIndexOfKey]?.isActive
    }
    const onChangeNavigation = (mainKey, key) => {
        let findIndexOfKey = sidebarState[mainKey]?.subMenu?.findIndex((d) => d?.key === key);
        const jsonManipulator = new JSONManipulator(setSubMenuActiveToFalse(sidebarState));
        jsonManipulator.setNestedValue(`${mainKey}.subMenu.${findIndexOfKey}.isActive`, true)
        setSidebarState(jsonManipulator.returnModifiedJSON())
        localStorage.setItem("sidebarState", JSON.stringify(jsonManipulator.returnModifiedJSON()))
        return navigate(sidebarState[mainKey]?.subMenu[findIndexOfKey]?.navigateTo)
    }

    //UI
    const renderCommunity = () => {
        return (
            <>
                <div value="Community" name="navigation menu" onClick={() => {
                    setSidebarState((prev) => ({
                        ...prev, COMMUNITY: {
                            ...prev.COMMUNITY,
                            isActive: !prev.COMMUNITY.isActive
                        }
                    }))
                }}
                    className={mergeClasses(styles.root, styles.mainMenu, "pointer")}>COMMUNITY
                    {sidebarState.COMMUNITY.isActive ?
                        <UpIcon /> : <DownIcon />}
                </div>

                {sidebarState?.COMMUNITY.isActive ? <>
                    <span onClick={() => onChangeNavigation("COMMUNITY", "Events")} className={''}><div
                        style={{ gap: '8px' }}
                        value="Events" name="navigation"
                        className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("COMMUNITY", "Events") ? "active-sidebar" : "")}>
                        <EventSidebarSVG />Events</div></span>
                    <span onClick={() => onChangeNavigation("COMMUNITY", "Announcements")}><div
                        style={{ gap: '8px' }}
                        value="News" name="navigation"
                        className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("COMMUNITY", "Announcements") ? "active-sidebar" : "")}>
                        <AnnouncementsSidebarSVG />Announcements</div></span>
                    <span onClick={() => onChangeNavigation("COMMUNITY", "SpaceClosures")}><div
                        style={{ gap: '8px' }}
                        value="News" name="navigation"
                        className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("COMMUNITY", "SpaceClosures") ? "active-sidebar" : "")}>
                        <SpaceClosuresSVG />Space Closures</div></span></>
                    : ""}
            </>
        )
    }

    const renderReservations = () => {
        return (
            <>
                <div value="Fleet" name="navigation menu" onClick={() => {
                    setSidebarState((prev) => ({
                        ...prev, RESERVATIONS: {
                            ...prev.RESERVATIONS,
                            isActive: !prev.RESERVATIONS.isActive
                        }
                    }))
                }}
                    className={mergeClasses(styles.root, styles.mainMenu, "pointer")}>RESERVATIONS {sidebarState.RESERVATIONS.isActive ?
                        <UpIcon /> : <DownIcon />}</div>
                {sidebarState?.RESERVATIONS.isActive ? <>
                    <span onClick={() => onChangeNavigation("RESERVATIONS", "Service Types")}><div
                        style={{ gap: '8px' }}
                        value="Service Types" name="navigation"
                        className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("RESERVATIONS", "Service Types") ? "active-sidebar" : "")}><ServiceTypesSidebarSVG /> <span
                            className={"vertical-center"}>Service Types</span></div></span>
                    <span onClick={() => onChangeNavigation("RESERVATIONS", "Providers")}>
                        <div style={{ gap: '8px' }}
                            value="Providers" name="navigation"
                            className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("RESERVATIONS", "Providers") ? "active-sidebar" : "")}><ProvidersSidebarSVG />Providers</div></span>
                    <span onClick={() => onChangeNavigation("RESERVATIONS", "Services")}><div
                        style={{ gap: '8px' }}
                        value="Services" name="navigation"
                        className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("RESERVATIONS", "Services") ? "active-sidebar" : "")}
                    ><ServicesSidebarSVG />Services</div></span>
                    <span onClick={() => onChangeNavigation("RESERVATIONS", "Room")}><div
                        style={{ gap: '8px' }}
                        value="Room"
                        name="navigation"
                        className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("RESERVATIONS", "Room") ? "active-sidebar" : "")}><RoomSidebarSVG />Room</div></span>
                    <span onClick={() => onChangeNavigation("RESERVATIONS", "Coach")}><div
                        style={{ gap: '8px' }}
                        value="Coach"
                        name="navigation"
                        className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("RESERVATIONS", "Coach") ? "active-sidebar" : "")}><CoachSidebarSVG />Coach</div></span>
                    <span onClick={() => onChangeNavigation("RESERVATIONS", "Rental")}><div
                        style={{ gap: '8px' }}
                        value="Rental" name="navigation"
                        className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("RESERVATIONS", "Rental") ? "active-sidebar" : "")}><RentalSidebarSVG />Rental</div></span>
                </>
                    : ""}
            </>
        )
    }

    const renderLocations = () => {
        return (
            <>
                <div value="Locations" name="navigation menu"
                    onClick={() => {
                        setSidebarState((prev) => ({
                            ...prev, LOCATIONS: {
                                ...prev.LOCATIONS,
                                isActive: !prev.LOCATIONS.isActive
                            }
                        }))
                    }}
                    className={mergeClasses(styles.root, styles.mainMenu, "pointer")}>LOCATIONS {sidebarState.LOCATIONS.isActive ?
                        <UpIcon /> : <DownIcon />}</div>
                {sidebarState?.LOCATIONS.isActive ? <>
                    <span onClick={() => onChangeNavigation("LOCATIONS", "Restaurants")}><div
                        style={{ gap: '8px' }}
                        value="Restaurants" name="navigation"
                        className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("LOCATIONS", "Restaurants") ? "active-sidebar" : "")}><RestaurantsSidebarSVG />Restaurants</div></span>
                    <span onClick={() => onChangeNavigation("LOCATIONS", "Driving Roads")}><div
                        style={{ gap: '8px' }}
                        value="DrivingRoads" name="navigation"
                        className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("LOCATIONS", "Driving Roads") ? "active-sidebar" : "")}><DrivingRoadsSidebarSVG />Driving Roads</div></span>
                    <span onClick={() => onChangeNavigation("LOCATIONS", "Attractions")}><div
                        style={{ gap: '8px' }}
                        value="Attractions" name="navigation"
                        className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("LOCATIONS", "Attractions") ? "active-sidebar" : "")}><AttractionsSidebarSVG />Attractions</div></span>
                </>
                    : ""}
            </>
        )
    }

    const renderPeople = () => {
        return (
            <>
                <div value="People" name="navigation menu"
                    onClick={() => {
                        setSidebarState((prev) => ({
                            ...prev, PEOPLE: {
                                ...prev.PEOPLE,
                                isActive: !prev.PEOPLE.isActive
                            }
                        }))
                    }}
                    className={mergeClasses(styles.root, styles.mainMenu, "pointer", "d-flex")}>PEOPLE {sidebarState.PEOPLE.isActive ?
                        <UpIcon /> : <DownIcon />}</div>
                {sidebarState?.PEOPLE.isActive ? <>
                    <span onClick={() => onChangeNavigation("PEOPLE", "Members")}><div
                        style={{ gap: '8px' }}
                        value="Members"
                        name="navigation"
                        className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("PEOPLE", "Members") ? "active-sidebar" : "")}><MembersSidebarSVG />Members</div></span>
                    <span onClick={() => onChangeNavigation("PEOPLE", "Coach")}><div
                        style={{ gap: '8px' }}
                        value="CoachMember"
                        name="navigation"
                        className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("PEOPLE", "Coach") ? "active-sidebar" : "")}><CoachSidebarSVG />Coach</div></span>
                    <span onClick={() => onChangeNavigation("PEOPLE", "Rooms")}><div
                        style={{ gap: '8px' }}
                        value="RoomAddEdit"
                        name="navigation"
                        className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("PEOPLE", "Rooms") ? "active-sidebar" : "")}><RoomSidebarSVG />Rooms</div></span>
                    <span onClick={() => onChangeNavigation("PEOPLE", "Groups")}><div
                        style={{ gap: '8px' }}
                        value="Groups"
                        name="navigation"
                        className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("PEOPLE", "Groups") ? "active-sidebar" : "")}><GroupsSidebarSVG />Groups</div></span>
                    <span onClick={() => onChangeNavigation("PEOPLE", "Vehicles")}><div
                        style={{ gap: '8px' }}
                        value="Vehicles"
                        name="navigation"
                        className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("PEOPLE", "Vehicles") ? "active-sidebar" : "")}><VehiclesSidebarSVG />Vehicles</div></span>

                    <span onClick={() => onChangeNavigation("PEOPLE", "Vehicle Keys")}><div
                        style={{ gap: '8px' }}
                        value="KeyCafeKeys" name="navigation"
                        className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("PEOPLE", "Vehicle Keys") ? "active-sidebar" : "")}><VehicleKeysSidebarSVG />Vehicle Keys</div></span>

                    <span onClick={() => onChangeNavigation("PEOPLE", "Vehicle Requests")}><div
                        style={{ gap: '8px' }}
                        value="VehicleRequests" name="navigation"
                        className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("PEOPLE", "Vehicle Requests") ? "active-sidebar" : "")}><VehicleRequestsSVG />Vehicle Requests</div></span>


                    <span onClick={() => onChangeNavigation("PEOPLE", "Message User")}><div
                        style={{ gap: '8px' }}
                        value="Notification" name="navigation"
                        className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("PEOPLE", "Message User") ? "active-sidebar" : "")}><MessageUserSidebarSVG /> Message User</div></span>

                    <span onClick={() => onChangeNavigation("PEOPLE", "Wallet History")}><div
                        style={{ gap: '8px' }}
                        value="UserWalletHistory" name="navigation"
                        className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("PEOPLE", "Wallet History") ? "active-sidebar" : "")}><POSHistorySVG /> Wallet History</div></span>

                </>
                    : ""}
            </>
        )
    }

    const renderAppSetting = () => {
        return (<>
            <span onClick={() => onChangeNavigation("PEOPLE", "App Settings")}><div
                style={{ marginLeft: '-12px', gap: '8px' }}
                value="AppSettings"
                name="navigation"
                className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("PEOPLE", "App Settings") ? "active-sidebar" : "")}><AppSettingsSidebarSVG /> App Settings</div></span>

        </>)
    }

    const renderPOS = () => {
        return (
            <>
                <div value="POS" name="navigation menu" onClick={() => {
                    setSidebarState((prev) => ({
                        ...prev, POS: {
                            ...prev.POS,
                            isActive: !prev.POS?.isActive
                        }
                    }))
                }}
                    className={mergeClasses(styles.root, styles.mainMenu, "pointer")}>POS
                    {sidebarState.POS?.isActive ?
                        <UpIcon /> : <DownIcon />}
                </div>
                {sidebarState?.POS?.isActive ? <>
                    <span onClick={() => onChangeNavigation("POS", "Orders")} className={''}>
                        <div style={{ gap: '8px' }} value="Orders" name="navigation"
                            className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("POS", "Orders") ? "active-sidebar" : "")}>
                            <POSProductSVG />Orders</div></span>

                    {/* <span onClick={() => onChangeNavigation("POS", "Products")} className={''}>
                        <div style={{ gap: '8px' }} value="Products" name="navigation"
                            className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("POS", "Products") ? "active-sidebar" : "")}>
                            <POSProductSVG />Products</div></span> */}
                    <span onClick={() => onChangeNavigation("POS", "OrderHistory")} className={''}>
                        <div style={{ gap: '8px' }} value="OrderHistory" name="navigation"
                            className={mergeClasses(styles.root, styles.subMenu, checkActiveStatus("POS", "OrderHistory") ? "active-sidebar" : "")}>
                            <POSHistorySVG />Order History</div></span>
                </>
                    : ""}
            </>
        )
    }

    // if (!accessPermission || accessPermission.length === 0) {
    //     return null; // or you can return a message indicating no permissions
    // }

    return (
        <>
            <div className={layoutState?.isSidebarOpen ? `wapleftnav open` : `wapleftnav`}
                style={layoutState?.isSidebarOpen ? {
                    height: "calc(100vh - 48px)",
                    overflow: "auto",
                    transition: "width 0.5s ease-in-out", // Added transition property
                    '&::-webkit-scrollbar': {
                        width: '0px',
                        background: 'transparent', // Optional: hides the scrollbar track
                    },
                    '&::-webkit-scrollbar': {
                        width: 0,
                        height: 0
                    }
                } : {
                    width: 0,
                    display: "none",
                    transition: "width 0.5s ease-in-out", // Added transition property
                }}>
                <div className={styles.wapleftnav}>
                    <DrawerInline separator open className={styles.root} style={{ position: 'relative' }}>
                        <DrawerBody className={styles.root}>
                            <MenuList checkedValues={checkedValues} onCheckedValueChange={onChange}
                                hasCheckmarks={false}>
                                {/* {renderCommunity()} */}
                                {/* {renderReservations()} */}
                                {/* {renderLocations()} */}
                                {/* {renderPeople()} */}
                                {/* {renderAppSetting()} */}
                                {/* {renderPOS()} */}
                                {(accessPermission.includes("Admin") ?
                                    [renderCommunity(), renderReservations(), renderLocations(), renderPeople(), renderPOS(), renderAppSetting()] :
                                    accessPermission.map(permission => {
                                        switch (permission) {
                                            case "Community":
                                                return renderCommunity();
                                            case "Reservations":
                                                return renderReservations();
                                            case "Locations":
                                                return renderLocations();
                                            case "People":
                                                return renderPeople();
                                            case "AppSetting":
                                                return renderAppSetting();
                                            case "POS":
                                                return renderPOS();
                                            default:
                                                return null;
                                        }
                                    })
                                )}
                                <div style={{ marginTop: '80px' }}></div>
                            </MenuList>
                            {/*<Button appearance="primary" shape="circular" onClick={logout}*/}
                            {/*    className={styles.logoutbutton} style={{paddingLeft:"12px"}}>Logout</Button>*/}
                        </DrawerBody>
                    </DrawerInline>
                </div>
                <div style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    width: '235px',
                    paddingLeft: '24px',
                    paddingTop: '16px',
                    paddingBottom: '16px',
                    borderTop: '1px solid #3A3A3A',
                    background: '#202020',
                    overflow: 'hidden'
                }}>
                    <div className={`${styles.logoutButton} pointer`} onClick={logout}>
                        <div><LogOutSVG /></div>
                        <div style={{ marginLeft: '12px' }}>Logout</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LeftNav;